<script>
  import { afterNavigate } from "$app/navigation";
  import Search from "$lib/admin/components/blocks/helpers/Search.svelte";
  import DarkMode from "$lib/main/DarkMode.svelte";
  import { slide } from "svelte/transition";
  import { isAuthenticated, project } from "$lib/services/store";
  import { loginAuth0 } from "$lib/services/auth0";
  import AuthProvider from "$lib/main/AuthProvider.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  let innerWidth = 0;
  let openKey = {};
  let opened = false;

  function toggleNav() {
    openKey = {};
    opened = !opened;
  }

  afterNavigate(() => {
    openKey = {};
    opened = false;
  });

  const isAuth0Enabled = import.meta.env.VITE_ENABLE_AUTH0 === "true";

  async function login() {
     const tournamentRoute = $project.settings.tournamentsUrl ?? "turneringar";
     const redirectPathName = window.location.pathname.includes(`/${tournamentRoute}`)
       ? `/${tournamentRoute}`
       : "/profile";
     await loginAuth0(redirectPathName);
  }
</script>

<svelte:window bind:innerWidth />

<AuthProvider>
  <header class="style-a">
    <div class="container">
      <a href="/" class="logo">
        <img
          alt={$project.name + " Logo"}
          src={$project.data?.imageFolders?.assets + $project.name_lower + "-logo.svg?h=22&q=100&auto=format"} />
      </a>

      <div class="navigation">
        {#if $project.menu?.darkMode === "active"}
          <DarkMode key={$project.key} />
        {/if}

        <div class="main-nav">
          <ul class="container">
            {#if $project.menu?.menu}
              {#each $project?.menu.menu ?? [] as item}
                <li class:hidden={!item?.main}>
                  <a href={item.path} on:click={() => (opened = false)}>{item.name}</a>
                </li>
              {/each}
            {/if}
          </ul>
        </div>

        {#if $project.menu?.search == "active"}
          <Search />
        {/if}

        {#if isAuth0Enabled}
          {#if $isAuthenticated}
            <a class="nav-login-button" href="/profile">
              <Icons name="user" width="20px" height="20px" fill="var(--clr-pri)" color="var(--clr-pri)" />
            </a>
          {:else}
            <a href={null} class="nav-login" on:click={() => login()}><Translate text="Log in" /></a>
          {/if}
        {/if}

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span role="button" tabindex="0" class="hamburger" on:click={toggleNav}>
          <div class:opened></div>
        </span>
      </div>
    </div>

    {#key openKey}
      <nav
        class="sub-nav"
        class:opened
        in:slide={{ duration: innerWidth > 991 ? 150 : 400 }}
        out:slide={{ duration: innerWidth > 991 ? 150 : 200 }}>
        <ul class="container">
          {#if $project.menu?.menu}
            {#each $project?.menu.menu ?? [] as item}
              <li class:hidden={item?.main}>
                <a href={item.path} on:click={() => (opened = false)}>{item.name}</a>
              </li>
            {/each}
          {/if}
        </ul>
      </nav>
    {/key}
  </header>
</AuthProvider>

<style>
  .nav-login-button {
    width: 28px;
    height: 28px;
    min-width: 28px;
    background: var(--bg-clr);
    padding: 0;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
