<script>
  import { project } from "$lib/services/store";
  import Toplist from "$lib/admin/components/blocks/blocks/Toplist.svelte";
  import List from "$lib/admin/components/blocks/blocks/List.svelte";
</script>

<div class="section-sidebar">
  {#if $project.key == 'ocb'}
    <div class="item-content">
      <h2>Dream11 Predictions</h2>
      <ul>
        <li>
          <a class="prediction" href="/fantasy/dream11/tit-vs-rs-19-feb-2023/">
            <span>TIT vs RS</span>
            <span>Dream11 Prediction</span>
          </a>
        </li>
        <li>
          <a class="prediction" href="/fantasy/dream11/tit-vs-rs-19-feb-2023/">
            <span>PXP vs SOA</span>
            <span>Dream11 Prediction</span>
          </a>
        </li>
        <li>
          <a class="prediction" href="/fantasy/dream11/tit-vs-rs-19-feb-2023/">
            <span>DJC vs RA</span>
            <span>Dream11 Prediction</span>
          </a>
        </li>
        <li class="end-more"><a href="/fantasy/dream11/">More Dream11 Predictions</a></li>
      </ul>
    </div>
    <List props={{
      "headingSize": "h4",
      "headingText": "Dream11 Predictions",
      "listType": "ul",
      "listItems": [
        "TIT vs RS",
        "PXP vs SOA"
      ]
    }} />

    <Toplist props={{
      "toplistId": 25,
      "headingSize": "h4",
      "headingText": "Best Betting Sites in India",
      "toplistType": "list",
      "toplistFocus": "sports",
      "toplistLength": 7,
      "toplistLinkUrl": "/betting-sites",
      "toplistLinkAnchor": "More Betting Sites"
    }} />
  {:else if $project.key == 'bse'}
    <Toplist props={{
      "toplistId": 21,
      "headingSize": "h4",
      "headingText": "Oddsbonusar 2023",
      "toplistType": "list",
      "toplistFocus": "sports",
      "toplistLength": 5,
      "toplistLinkUrl": "/oddsbonusar",
      "toplistLinkAnchor": "Fler oddsbonusar"
    }} />
  {/if}
</div>