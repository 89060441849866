<script>
  export let key
  let darkMode = false

  function handleSwitchDarkMode() {
    darkMode = !darkMode;

    darkMode
      ? document.getElementsByClassName('p-' + key)[0].classList.add('dark')
      : document.getElementsByClassName('p-' + key)[0].classList.remove('dark');
    }
</script>

<div class="mode-toggle">
  <input checked={darkMode} on:click={handleSwitchDarkMode} type="checkbox" id="mode-toggler" />
  <label for="mode-toggler" />
</div>

<style>
  .mode-toggle {
    display: grid;
    place-content: center;
  }
  .mode-toggle input {
    display: none;
  }

  .mode-toggle input + label {
    display: inline-block;
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    border-radius: 9999px;
    transition-duration: 300ms;
    content: '';
  }

  .mode-toggle input:not(:checked) + label {
    background-color: #fbbf24
  }

  .mode-toggle input:checked + label {
    background-color: transparent;
    box-shadow: inset -0.4rem -0.4rem 0.01rem 0.01rem #ddd; /* TODO: this seems to be correct in moz, but below must be used for Chromium and Safari.. */
    box-shadow: inset -0.4rem -0.4rem #ddd;
  }
</style>