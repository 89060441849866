<script lang="ts">
  import { onMount } from "svelte";
  import { isAuthenticated, isUserVerified, user, authReady, isEmailVerified } from "$lib/services/store";
  import { auth0, logoutAuth0 } from "$lib/services/auth0";
  import { getUser } from "$lib/services/api";
  import { formatDate } from "$lib/common/helpers/date";
  import type { Auth0User, User } from "@/src/types/users";

  const isAuth0Enabled: boolean = import.meta.env.VITE_ENABLE_AUTH0 === "true";

  let logoutTimeout: ReturnType<typeof setTimeout>;

  const handleLogout = (): void => {
    isAuthenticated.set(false);
    user.set(null);
    isUserVerified.set(false);

    localStorage.removeItem("token");
    localStorage.removeItem("user");

    logoutAuth0();
  };

  const resetInactivityTimer = (): void => {
    clearTimeout(logoutTimeout);
    logoutTimeout = setTimeout(() => {
      handleLogout();
    }, 3600000); // 1 hour
  };

  const setupInactivityListeners = (): void => {
    window.addEventListener("mousedown", resetInactivityTimer);
  };

  onMount(async () => {
    if (!isAuth0Enabled) {
      authReady.set(true);
      return;
    }

    if (window.location.search.includes("code=")) {
      await auth0.handleRedirectCallback();
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    const authStatus: boolean = await auth0.isAuthenticated();

    if (authStatus) {
      const token: string = await auth0.getTokenSilently();
      const { sub, email, updated_at, email_verified }: Auth0User = await auth0.getUser();

      if (email_verified) {
        isEmailVerified.set(true);
      } else {
        isEmailVerified.set(false);
        return await auth0.logout({
          async openUrl() {
            await window.location.replace('/profile');
          },
        });
      }

      const { data: apiData } = await getUser(sub, token);
      const userData: User = apiData || {
        email: email,
        externalId: sub,
        dateJoined: formatDate(updated_at),
      };

      isAuthenticated.set(authStatus);
      user.set(userData);

      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(userData));

      resetInactivityTimer();
      setupInactivityListeners();
    } else {
      const token: string | null = localStorage.getItem("token");
      const userData: string | null = localStorage.getItem("user");

      if (userData) {
        const parsedData: User = JSON.parse(userData);
        const { username, ageConsent, termsConsent, phoneNumber } = parsedData;
        user.set(parsedData);
        isUserVerified.set(!!(username && ageConsent && termsConsent && phoneNumber));
      }

      if (token) {
        isAuthenticated.set(Boolean(token));
        resetInactivityTimer();
        setupInactivityListeners();
      }
    }
    authReady.set(true);

    return () => {
      window.removeEventListener("mousedown", resetInactivityTimer);
    };
  });
</script>

<slot />
